import * as React from 'react'
import { Block } from '@thesisedu/web/dist'
import { KnowledgeBaseSearch, FeaturedContent } from '@thesisedu/gatsby-knowledge-base'
import { Container, LargeH1, Subtitle } from '../../components/styled'
import { SEO } from '../../components/SEO'
import { SupportWidget } from '../../components/support/SupportWidget'

const KnowledgeBaseContent: React.FC = () => {
  return (
    <Container>
      <SEO title={'Knowledge Base'} />
      <Block marginTop={'@size-section-md'} marginBottom={'@size-xxl'}>
        <LargeH1 isBlock={false}>Knowledge Base</LargeH1>
        <Block marginTop={'@size-xs'}>
          <Subtitle>Learn how to make the most out of Prodigies Academy.</Subtitle>
        </Block>
      </Block>
      <KnowledgeBaseSearch />
      <Block marginTop={'@size-l'}>
        <FeaturedContent />
      </Block>
      <Block marginTop={'@size-section-md'}>
        <SupportWidget />
      </Block>
    </Container>
  )
}

const KnowledgeBasePage: React.FC = () => <KnowledgeBaseContent />

export default KnowledgeBasePage
